<template>
  <div class="animated fadeIn">
    <div id="snackbar"></div>
    <b-row>
      <b-col sm="12">
        <b-card>
          <div slot="header">
            <strong>Country</strong>
            <small>Form</small>
          </div>
          <b-form v-on:submit.prevent.self="submit">
            <b-row>
              <b-col sm="6">
                <b-form-group>
                  <label for="title">
                    Title
                    <span class="text-danger">*</span>
                  </label>
                  <b-form-input
                    type="text"
                    required
                    v-model="country.CountryName"
                    id="title"
                    placeholder="Country Name"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col sm="6">
                <b-form-group>
                  <label for="time_offset">
                    Time Offset
                    <span class="text-danger">*</span>
                  </label>
                  <b-form-input
                    type="text"
                    step="any"
                    required
                    v-model="country.Time_Offset"
                    id="time-offset"
                    placeholder="GMT+minutes"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="6">
                <b-form-group>
                  <label for="iso_code">
                    ISO Code
                    <span class="text-danger">*</span>
                  </label>
                  <b-form-input
                    type="text"
                    step="any"
                    required
                    v-model="country.ISOCode"
                    id="iso-code"
                    placeholder="ISO Alpha Code"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col sm="6">
                <b-form-group>
                  <label for="currency">
                    Currency
                    <span class="text-danger">*</span>
                  </label>
                  <b-form-input
                    type="text"
                    required
                    v-model="country.Currency_ISOCode"
                    id="currency"
                    placeholder="Currency Code"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col sm="6">
                <b-form-group>
                  <label for="logo">
                    Flag
                    <span class="text-danger">*</span>
                  </label>
                  <b-form-file
                    id="fileInput"
                    v-on:change="uploadFlag($event.target)"
                    ref="fileinput"
                    :required="country.CountryID == null ? true : false"
                    accept="image/jpeg, image/png, image/gif"
                    :plain="true"
                  ></b-form-file>
                  <input type="hidden" v-model="country.CountryFlag" />
                </b-form-group>
              </b-col>
              <b-col sm="6">
                <b-form-group>
                  <label for="status">
                    Status
                    <span class="text-danger">*</span>
                  </label>
                  <br />
                  <c-switch
                    class="mx-1"
                    color="primary"
                    v-model="country.CountryIsActive"
                    defaultChecked
                    variant="3d"
                    label
                    v-bind="labelIcon"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="6">
                <div id="preview">
                  <img
                    v-if="country.CountryFlag != ''"
                    :src="BaseURL+country.CountryFlag"
                    class="img-fluid"
                  />
                </div>
              </b-col>
            </b-row>
            <b-row class="mt-3">
              <b-col sm="6">
                <b-button type="submit" size="sm" variant="primary">
                  <i class="fa fa-dot-circle-o"></i>
                  {{ commontext.submitButton }}
                </b-button>
              </b-col>

              <b-col sm="6">
                <b-button @click="resetForm" size="sm" variant="success">
                  <i class="fa fa-dot-circle-o"></i> Reset
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-card>

        <b-card>
          <b-card-header>
            <i class="icon-menu mr-1"></i>List of Countries
            <div class="card-header-actions"></div>
          </b-card-header>
          <b-card-body>
            <v-client-table
              :columns="columns"
              :data="data"
              :options="options"
              :theme="theme"
              id="dataTable"
            >
              <div slot="CountryIsActive" slot-scope="props">
                <c-switch
                  class="mx-1"
                  color="primary"
                  v-model="props.row.CountryIsActive"
                  variant="3d"
                  label
                  v-bind="labelIcon"
                  @change.native="changeStatus(props.row.CountryID)"
                />
              </div>

              <div slot="CountryID" slot-scope="props">
                <i class="fa fa-edit" @click="editCountry(props.row.CountryID)"></i>
              </div>
            </v-client-table>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-modal
      id="ModalConfirmationOnSubmit"
      ref="ModalConfirmationOnSubmit"
      @ok="handleModalOkay"
      :title="modalObject.title"
      class="center-footer"
      footer-class="justify-content-center border-0"
      cancel-variant="outline-danger"
      ok-variant="outline-success"
      centered
      hide-header
      no-close-on-esc
      no-close-on-backdrop
    >
      <div class="d-block text-center">
        <div>{{ modalObject.message }}</div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { Switch as cSwitch } from "@coreui/vue";
import MasterService from "@/services/MasterService";
import CinemaService from "@/services/CinemaService";
import UploadService from "@/services/UploadService";

export default {
  name: "Countries",
  components: {
    cSwitch
  },
  data() {
    return {
      BaseURL: "",
      commontext: {
        submitButton: "Submit"
      },
      country: {
        CountryID: null,
        CountryName: "",
        Time_Offset: "",
        Flag: 1,
        Currency_ISOCode: "",
        ISOCode: "",
        CountryIsActive: true,
        CountryFlag: ""
      },
      labelIcon: {
        dataOn: "\u2713",
        dataOff: "\u2715"
      },
      countries: [],
      columns: ["CountryName", "ISOCode", "CountryIsActive", "CountryID"],
      data: [],
      options: {
        headings: {
          name: "Country Name",
          ISOCode: "ISO Code",
          CountryID: "Edit",
          CountryIsActive: "Status"
        },
        sortable: ["CountryName", "ISOCode"],
        filterable: ["CountryName", "ISOCode"],
        sortIcon: {
          base: "fa",
          up: "fa-sort-asc",
          down: "fa-sort-desc",
          is: "fa-sort"
        },
        pagination: {
          chunk: 5,
          edge: false,
          nav: "scroll"
        }
      },
      useVuex: false,
      theme: "bootstrap4",
      template: "default"
    };
  },
  mounted() {
    this.getCountries();
  },
  methods: {
    resetForm: function() {
      this.commontext.submitButton = "Submit";
      this.country = {
        CountryID: null,
        CountryName: "",
        Time_Offset: "",
        Flag: 1,
        Currency_ISOCode: "",
        ISOCode: "",
        CountryIsActive: true,
        CountryFlag: ""
      };
      this.BaseURL = "";
      // var previewDiv = document.getElementById("preview");
      // var previewImg = "";
      // var previewImg = document.createElement("img");
      // while (previewDiv.firstChild) {
      //   previewDiv.removeChild(previewDiv.firstChild);
      // }
      this.$refs.fileinput.reset();
      this.countries = [];
      this.getCountries();
    },
    uploadFlag: function(image) {
      let self = this;
      var formdata = new FormData();
      formdata.append("assets/images/countryflags", image.files[0]);
      formdata.append("rename", this.country.CountryName);
      // let res = await CinemaService.uploadFiles(formdata);
      UploadService.uploadFiles(formdata)
        .then(response => {
          const { data } = response;
          data.Message ? self.showMessageSnackbar(data.Message) : "";
          if (data.Status) {
            this.country.CountryFlag = data.Path;
            this.BaseURL = data.BaseURL;
          }
        })
        .catch(error => {
          self.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    // uploadFlag: function(image) {
    //   let self = this;
    //   var formdata = new FormData();
    //   var file = image.files[0];
    //   formdata.append("assets/images/countryflags", image.files[0]);
    //   formdata.append("rename", this.country.CountryName);
    //   // let res = await CinemaService.uploadFiles(formdata);
    //   UploadService.uploadFiles(formdata)
    //     .then(response => {
    //       const { data } = response;
    //       data.Message ? self.showMessageSnackbar(data.Message) : "";
    //       if (data.Status) {
    //         this.country.CountryFlag = data.Path;
    //         var previewDiv = document.getElementById("preview");
    //         var previewImg = "";
    //         var previewImg = document.createElement("img");
    //         while (previewDiv.firstChild) {
    //           previewDiv.removeChild(previewDiv.firstChild);
    //         }
    //         previewDiv.appendChild(previewImg);
    //         previewImg.src = window.URL.createObjectURL(image.files[0]);
    //         previewImg.classList.add("img-fluid");
    //       }
    //     })
    //     .catch(error => {
    //       self.showMessageSnackbar(
    //         "Oops! Something went wrong. kindly contact your administrator"
    //       );
    //     });
    // },
    getCountries: function() {
      // let res = await MasterService.getCountries();
      MasterService.getCountries()
        .then(response => {
          const { data } = response;
          if (data.Status && data.CountryList.length > 0) {
            this.countries = data.CountryList.reverse();
            this.data = this.countries;
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    validation: function() {
      if (this.country.CountryName == "") {
        this.showMessageSnackbar("Country Name Cannot be empty.");
        return false;
      } else if (this.country.Time_Offset == "") {
        this.showMessageSnackbar("Country Time Offset Cannot be empty.");
        return false;
      } else if (this.country.ISOCode == "") {
        this.showMessageSnackbar("Country ISO Code Cannot be empty.");
        return false;
      } else if (this.country.Currency_ISOCode == "") {
        this.showMessageSnackbar("Country Currency Cannot be empty.");
        return false;
      } else {
        return true;
      }
    },
    submit: function() {
      if (this.validation()) {
        let payload = {
          title: "Confirmation",
          message: "Please re-check the data before submit"
        };
        this.showSubmitConfirmation(payload);
      }
    },
    submitFinalData: function() {
      this.closeModal("ModalConfirmationOnSubmit");

      MasterService.addCountry(this.country)
        .then(response => {
          const { data } = response;
          data.Message ? this.showMessageSnackbar(data.Message) : "";
          if (data.Status) {
            this.resetForm();
          }
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    editCountry: function(CountryID) {
      // let res = await MasterService.getCountryByID(CountryID);

      let payload = {
        CountryID
      };
      MasterService.getCountryByID(payload)
        .then(response => {
          const { data } = response;
          if (data.Status && data.CountryList.length > 0) {
            this.country = {
              CountryID: null,
              CountryName: "",
              Time_Offset: "",
              Flag: 1,
              Currency_ISOCode: "",
              ISOCode: "",
              CountryIsActive: true,
              CountryFlag: "",
              ...data.CountryList[0]
            };
            this.commontext.submitButton = "Update Country";

            this.country.Flag = 2;
            this.BaseURL = data.CountryList[0].BaseURL;
            // var previewDiv = document.getElementById("preview");
            // var previewImg = "";
            // var previewImg = document.createElement("img");
            // while (previewDiv.firstChild) {
            //   previewDiv.removeChild(previewDiv.firstChild);
            // }
            // previewDiv.appendChild(previewImg);
            // previewImg.src =
            //   data.CountryList[0].BaseURL + this.country.CountryFlag;
            // previewImg.classList.add("img-fluid");
            this.scrollToTop();
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    changeStatus: function(ID) {
      let payload = {
        TableName: "CountryMaster",
        ID
      };
      MasterService.activateOrDeactivate(payload)
        .then(response => {
          const { data } = response;
          this.showMessageSnackbar(data.Message);
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    // Show Confirmmation Dialog
    handleModalOkay: function(evt) {
      // evt.preventDefault();
      this.modalObject.count = 1;
      this.submitFinalData();
    },
    showSubmitConfirmation: function(payload) {
      this.modalObject.title = payload.title; // "Confirmation";
      this.modalObject.message = payload.message; // "Please re-check the data before submit";
      this.openModal("ModalConfirmationOnSubmit");
    },
    openModal: function(ref) {
      this.$refs[ref].show();
    },
    closeModal: function(ref) {
      this.$refs[ref].hide();
      this.modalObject.title = ""; // "Confirmation";
      this.modalObject.message = ""; // "Please re-check the data before submit";
    },
    // Show Confirmmation Dialog End
    // Show Error Message
    showMessageSnackbar: function(message) {
      let x = document.getElementById("snackbar");
      x.className = "show";
      x.innerHTML = message;
      setTimeout(function() {
        x.className = x.className.replace("show", "");
      }, 3000);
    }
  }
};
</script>
<style>
.fa-edit {
  cursor: pointer;
  font-size: 18px;
}
.fa-edit:hover {
  color: #20a8d8;
}
</style>
